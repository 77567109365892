html {
  font-size: 12px;
}

@media screen and (min-width: 600px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  html {
    font-size: 18px;
  }
}

@media screen and (min-width: 1600) {
  html {
    font-size: 20px;
  }
}
